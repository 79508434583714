import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Play, Pause } from 'lucide-react'

interface MediaPlayerProps {
  file: string | null;
  fileType: 'audio' | 'video';
}

export default function MediaPlayer({ file, fileType }: MediaPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const onLoadedMetadata = useCallback(() => {
    const mediaElement = fileType === 'audio' ? audioRef.current : videoRef.current;
    if (mediaElement && isFinite(mediaElement.duration)) {
      setDuration(mediaElement.duration);
    }
  }, [fileType]);

  const onTimeUpdate = useCallback(() => {
    const mediaElement = fileType === 'audio' ? audioRef.current : videoRef.current;
    if (mediaElement) {   
      if (isFinite(mediaElement.duration)) {
        setDuration(mediaElement.duration);
      }
      setCurrentTime(mediaElement.currentTime);
    }
  }, [fileType]);

  const onEnded = useCallback(() => {
    const mediaElement = fileType === 'audio' ? audioRef.current : videoRef.current;
    if (mediaElement) {
      setCurrentTime(mediaElement.duration);
      setIsPlaying(false);
    }
  }, [fileType]);

  useEffect(() => {
    const mediaElement = fileType === 'audio' ? audioRef.current : videoRef.current;

    if (file && mediaElement) {
      mediaElement.src = file;

      mediaElement.addEventListener('loadedmetadata', onLoadedMetadata);
      mediaElement.addEventListener('timeupdate', onTimeUpdate);
      mediaElement.addEventListener('ended', onEnded);
    }

    return () => {
      if (mediaElement) {
        mediaElement.pause();
        mediaElement.removeEventListener('loadedmetadata', onLoadedMetadata);
        mediaElement.removeEventListener('timeupdate', onTimeUpdate);
        mediaElement.removeEventListener('ended', onEnded);
      }
    };
  }, [file, fileType, onLoadedMetadata, onTimeUpdate, onEnded]);

  const togglePlayPause = () => {
    const mediaElement = fileType === 'audio' ? audioRef.current : videoRef.current;
    if (mediaElement) {
      if (isPlaying) {
        mediaElement.pause();
      } else {
        mediaElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const seekTime = Number(e.target.value)
    setCurrentTime(seekTime)
    const mediaElement = fileType === 'audio' ? audioRef.current : videoRef.current;
    if (mediaElement) {
      mediaElement.currentTime = seekTime
      // Add this condition to update the video frame when paused
      if (fileType === 'video' && !isPlaying) {
        (mediaElement as HTMLVideoElement).requestVideoFrameCallback(() => {
          // This callback ensures the frame is updated
        });
      }
    }
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  return (
    <div className="bg-neutral-200 rounded-lg p-4">
      {fileType === 'video' ? (
        <video
          ref={videoRef}
          className="w-full mb-4"
          onClick={togglePlayPause}
        />
      ) : (
        <audio ref={audioRef} />
      )}
      <div className="flex items-center space-x-4">
        <button 
          onClick={togglePlayPause} 
          className="p-2 rounded-full bg-primary text-white hover:bg-primary-hover"
          aria-label={isPlaying ? "Pause" : "Play"}
        >
          {isPlaying ? <Pause className="h-6 w-6" /> : <Play className="h-6 w-6" />}
        </button>
        <div className="flex-grow flex items-center space-x-2">
          <span className="text-sm text-gray-500">{formatTime(currentTime)}</span>
          <input
            type="range"
            min="0"
            max={duration}
            step="0.01"
            value={currentTime}
            onChange={handleSeek}
            className="custom-range flex-grow"
            aria-label="Seek"
          />
          <span className="text-sm text-gray-500">{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  )
}