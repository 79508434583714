import React, { useState, useRef, useEffect } from 'react';
import { Mic, Video, Square, Check, Trash2 } from 'lucide-react';
import { motion } from 'framer-motion';

interface RecorderProps {
  onRecordingComplete: (file: File) => void;
}

export default function Recorder({ onRecordingComplete }: RecorderProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedMedia, setRecordedMedia] = useState<File | null>(null);
  const [isVideoMode, setIsVideoMode] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const videoPreviewRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    if (isVideoMode) {
      startVideoPreview();
    } else {
      stopVideoPreview();
    }
    return () => {
      stopVideoPreview();
    };
  }, [isVideoMode]);

  const startVideoPreview = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      if (videoPreviewRef.current) {
        videoPreviewRef.current.srcObject = stream;
      }
      streamRef.current = stream;
    } catch (error) {
      console.error('Error starting video preview:', error);
    }
  };

  const stopVideoPreview = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    if (videoPreviewRef.current) {
      videoPreviewRef.current.srcObject = null;
    }
    streamRef.current = null;
  };

  const startRecording = async () => {
    try {
      let stream: MediaStream;
      if (isVideoMode && streamRef.current) {
        stream = streamRef.current;
      } else {
        const constraints = isVideoMode
          ? { audio: true, video: true }
          : { audio: true };
        stream = await navigator.mediaDevices.getUserMedia(constraints);
      }

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const mimeType = isVideoMode ? 'video/webm' : 'audio/webm';
        const blob = new Blob(chunksRef.current, { type: mimeType });
        const fileName = isVideoMode ? 'recorded_video.webm' : 'recorded_audio.webm';
        const file = new File([blob], fileName, { type: mimeType });
        setRecordedMedia(file);
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);

      // Stop all tracks in the current stream
      if (!isVideoMode && mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    }
  };

  const submitRecording = () => {
    if (recordedMedia) {
      onRecordingComplete(recordedMedia);
      setRecordedMedia(null);
    }
  };

  return (
    <div className="text-center">
      <h3 className="text-xl font-semibold mb-6">Record Directly</h3>
      <div className="flex justify-center items-center space-x-4 mb-6">
        <span className={`text-sm font-medium ${!isVideoMode ? 'text-accent' : 'text-neutral-600'}`}>Audio</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={isVideoMode}
            onChange={() => setIsVideoMode(!isVideoMode)}
          />
          <div className="w-14 h-7 bg-neutral-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-accent-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-accent"></div>
        </label>
        <span className={`text-sm font-medium ${isVideoMode ? 'text-accent' : 'text-neutral-600'}`}>Video</span>
      </div>
      {isVideoMode && (
        <div className="mb-6">
          <video 
            ref={videoPreviewRef} 
            className="w-full max-w-md mx-auto rounded-lg shadow-md transform scale-x-[-1]" 
            autoPlay 
            muted 
            playsInline 
          />
        </div>
      )}
      <div className="flex justify-center space-x-4 mb-6">
        {!isRecording ? (
          <button
            onClick={startRecording}
            className="p-4 rounded-full bg-accent text-white hover:bg-accent-hover transition-colors shadow-md"
            aria-label="Start recording"
          >
            {isVideoMode ? <Video className="h-6 w-6" /> : <Mic className="h-6 w-6" />}
          </button>
        ) : (
          <motion.button
            onClick={stopRecording}
            className="p-4 rounded-full bg-accent text-white hover:bg-neutral-300 transition-colors shadow-md"
            aria-label="Stop recording"
            animate={{ 
              scale: [1, 1.1, 1],
              opacity: [1, 0.5, 1],
            }}
            transition={{ 
              repeat: Infinity, 
              duration: 1,
              ease: "easeInOut"
            }}
          >
            <Square className="h-6 w-6" />
          </motion.button>
        )}
      </div>
      {recordedMedia && (
        <div className="flex justify-center space-x-4">
          <button
            onClick={submitRecording}
            className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-hover transition-colors shadow-md flex items-center justify-center"
          >
            <Check className="h-5 w-5 mr-2" />
            Submit Recording
          </button>
          <button
            onClick={() => setRecordedMedia(null)}
            className="px-6 py-2 bg-neutral-200 text-neutral-700 rounded-lg hover:bg-neutral-300 transition-colors shadow-md flex items-center justify-center"
          >
            <Trash2 className="h-5 w-5 mr-2" />
            Discard Recording
          </button>
        </div>
      )}
    </div>
  );
}