import React, { useState } from 'react'
import { Download, ThumbsUp, ThumbsDown } from 'lucide-react'
import MediaPlayer from './MediaPlayer'

interface ResultsPageProps {
  originalFile: string | null;
  processedFile: string | null;
  outputFileName: string | null;
  fileType: 'audio' | 'video';
  text: string | null;
  onNewFile: () => void;
}

export default function ResultsPage({
  originalFile,
  processedFile,
  outputFileName,
  fileType,
  onNewFile,
  text
}: ResultsPageProps) {
  const [feedback, setFeedback] = useState<boolean | null>(null)

  const handleDownload = (file: string | null) => {
    if (file) {
      window.open(file);
    }
  }

  const handleFeedback = (isPositive: boolean) => {
    setFeedback(isPositive)
    // Implement feedback submission logic here
  }

  return (
    <div className="p-8">
      <div className="mb-8 space-y-8 md:space-y-0 md:flex md:space-x-8">
        <div className="md:w-1/2">
          <h2 className="text-xl font-semibold mb-4">Original {fileType === 'audio' ? 'Audio' : 'Video'}</h2>
          <MediaPlayer file={originalFile} fileType={fileType} key={originalFile} />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl font-semibold mb-4">Processed {fileType === 'audio' ? 'Audio' : 'Video'}</h2>
          {processedFile && <MediaPlayer file={processedFile} fileType={fileType} key={processedFile} />}
        </div>
      </div>

      {text && (
        <div className="bg-white shadow-md rounded-lg p-6 max-h-60 overflow-y-auto mb-8">
          <p className="text-gray-700 whitespace-pre-wrap">{text}</p>
        </div>
      )}

      <div className="flex flex-col items-center space-y-4 mb-8">
        <button
          onClick={() => handleDownload(processedFile)}
          className="w-full sm:w-auto px-6 py-2 bg-secondary text-white rounded-lg hover:bg-secondary-hover transition-colors flex items-center justify-center"
        >
          <Download className="h-5 w-5 mr-2" />
          Download Processed {fileType === 'audio' ? 'Audio' : 'Video'}
        </button>
        <button
          onClick={onNewFile}
          className="w-full sm:w-auto px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-hover transition-colors"
        >
          Process Another File
        </button>
      </div>
      <div className="text-center">
        <p className="text-neutral-600 mb-2">How was the voice clarity?</p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={() => handleFeedback(true)}
            className={`p-2 rounded-full ${
              feedback === true ? 'bg-secondary text-white' : 'bg-neutral-200 text-neutral-600'
            } hover:bg-secondary-hover hover:text-white transition-colors`}
            aria-label="Positive feedback"
          >
            <ThumbsUp className="h-6 w-6" />
          </button>
          <button
            onClick={() => handleFeedback(false)}
            className={`p-2 rounded-full ${
              feedback === false ? 'bg-accent text-white' : 'bg-neutral-200 text-neutral-600'
            } hover:bg-accent-hover hover:text-white transition-colors`}
            aria-label="Negative feedback"
          >
            <ThumbsDown className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  )
}