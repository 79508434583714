import React, { useRef } from 'react';
import { Upload } from 'lucide-react';
import Recorder from './Recorder';

interface UploadRecordInterfaceProps {
  onFileUpload: (file: File) => void;
}

export default function UploadRecordInterface({ onFileUpload }: UploadRecordInterfaceProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileUpload(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      onFileUpload(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className=" mx-auto p-8 bg-white rounded-xl shadow-md">
      <h2 className="md:text-2xl sm:text-xl font-semibold text-center">Upload or Record Media</h2>
      <p className="text-center text-gray-500 md:text-xl sm:text-lg mb-6">Only single-speaker audio is supported</p>
      <div
        className="border-2 border-dashed border-neutral-300 rounded-lg p-4 sm:p-12 mb-8 text-center cursor-pointer hover:border-primary transition-colors"
        onClick={() => fileInputRef.current?.click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Upload className="mx-auto h-16 w-16 text-neutral-400 mb-4" />
        <p className="text-neutral-600 text-lg mb-2 hidden sm:block">Drag and drop your audio or video file here</p>
        <p className="text-neutral-500 hidden sm:block">or</p>
        <button className="sm:mt-4 px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-hover transition-colors">
          Browse Files
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
          accept="audio/*,video/*"
        />
      </div>
      <div className="border-t border-neutral-200 pt-8">
        <Recorder onRecordingComplete={onFileUpload} />
      </div>
    </div>
  );
}