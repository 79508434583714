import React from 'react'

interface ProcessingScreenProps {
  progress: number
}

export default function ProcessingScreen({ progress }: ProcessingScreenProps) {
  return (
    <div className="p-8 text-center">
      <h2 className="text-2xl font-semibold mb-4 text-neutral-700">Processing your file...</h2>
      <div className="w-full bg-neutral-200 rounded-full h-2.5 mb-4 overflow-hidden">
        <div
          className={`h-2.5 rounded-full ${progress === 0 ? 'animate-progress-indeterminate bg-gradient-to-r from-primary via-primary to-primary-light' : 'bg-primary'}`}
          style={{ width: progress === 0 ? '100%' : `${progress}%` }}
        ></div>
      </div>
      <p className="text-neutral-600">
        {progress === 0 ? 'Starting...' : `${progress.toFixed(1)}% complete`}
      </p>
      <p className="mt-4 text-sm text-neutral-500">This may take a few minutes. Please don't close this page.</p>
    </div>
  )
}
