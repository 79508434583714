import React, { useState, useEffect } from 'react'
import UploadRecordInterface from './UploadRecordInterface'
import ProcessingScreen from './ProcessingScreen'
import ResultsPage from './ResultsPage'
import { motion, AnimatePresence } from 'framer-motion'
import { AlertCircle, X, Zap, Mic, AudioWaveform } from 'lucide-react'

interface FileInfo {
  id: string;
  type: 'audio' | 'video';
}

interface FileStatus {
  status: string
  text?: string
  fname?: string
  original_path?: string
  progress?: number
}

interface FileStatus {
  status: string
  text?: string
  fname?: string
  original_path?: string
  progress?: number
}

const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api' : 'https://ext-demo.fly.dev/api'

export default function VoiceClarityApp() {
  const [currentStep, setCurrentStep] = useState('upload')
  const [fileInfo, setFileInfo] = useState<FileInfo | null>(null)
  // const [originalFile, setOriginalFile] = useState<string | null>(null)
  // const [processedFile, setProcessedFile] = useState<string | null>(null)
  // const [progress, setProgress] = useState(0)
  // const [text, setText] = useState<string | null>(null)
  const [fileStatus, setFileStatus] = useState<FileStatus | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [serverStatus, setServerStatus] = useState<string | null>(null)

  const handleFileUpload = async (uploadedFile: File) => {
    setCurrentStep('processing')
    setError(null)
    setFileInfo(null)
    setFileStatus({ status: 'processing', progress: 0 })

    const formData = new FormData()
    formData.append('file', uploadedFile)

    try {
      const response = await fetch(`${API_URL}/upload/`, {
        method: 'POST',
        body: formData,
      })
      const data = await response.json()
      setFileInfo({ id: data.file_id, type: data.file_type })
    } catch (error) {
      console.error('Error uploading file:', error)
      setError(error instanceof Error ? error.message : 'Error uploading file')
      setCurrentStep('upload')
    }
  }

  useEffect(() => {
    const checkStatus = async () => {
      if (fileInfo) {
        try {
          const response = await fetch(`${API_URL}/status/${fileInfo.id}/`)
          const data = await response.json()
          if (data.error) {
            console.error('Error checking status:', data.error)
            setError(data.error)
            setCurrentStep('upload')
          } else if (data.status === 'completed') {
            setFileStatus({ status: 'completed', fname: data.fname, original_path: data.original_path, text: data.text })
            setCurrentStep('results')
          } else if (data.status === 'failed') {
            console.error('Processing failed')
            setError('Processing failed: ' + data.text)
            setCurrentStep('upload')
          } else {
            // Update progress
            var progress = data.progress || 0
            progress = Math.min(progress, 100)
            progress = Math.max(progress, 0)
            setFileStatus({ status: 'processing', progress: progress })
            setTimeout(checkStatus, 2000)  // Check again after 2 seconds
          }
        } catch (error) {
          console.error('Error checking status:', error)
          setError('Error checking status: ' + error)
          setCurrentStep('upload')
        }
      }
    }

    if (currentStep === 'processing') {
      checkStatus()
    }
  }, [currentStep, fileInfo])

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        const response = await fetch(`${API_URL}/server_status`)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setServerStatus('ok')
      } catch (error) {
        console.error('Error checking server status:', error)
        setServerStatus(null)
        setTimeout(checkServerStatus, 1000)
      }
    }
    checkServerStatus()
  }, [serverStatus])

  const handleNewFile = () => {
    setFileInfo(null)
    setFileStatus(null)
    setCurrentStep('upload')
  }

  const clearError = () => setError(null)

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-purple-100 flex flex-col">
      <nav className="bg-white bg-opacity-90 backdrop-blur-md shadow-lg sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <a href="https://higginslabs.ai" className="flex items-center">
                <AudioWaveform className="h-8 w-8 text-indigo-600" />
                <span className="ml-2 font-semibold text-xl text-gray-900">Higgins Labs</span>
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <a href="https://higginslabs.ai/demo" className="text-gray-700 hover:text-indigo-600 transition duration-300">Samples</a>
              <a href="https://ext-demo.pages.dev" className="flex items-center px-3 py-2 text-gray-700 hover:text-indigo-600 transition duration-300">
                Live Demo
                {serverStatus && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 500, damping: 10 }}
                  >
                    <Zap className="inline-block w-4 h-4 ml-2" />
                  </motion.div>
                )}
              </a>
            </div>
          </div>
        </div>
      </nav>

      <main className="flex-grow container mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center md:mb-12"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block mb-4">Higgins Labs</span>
            <span className="block text-primary">Accent Reduction Demo</span>
          </h1>

          <h2 className="mt-6 md:text-2xl sm:text-xl font-semibold text-center">Remove heavy accents from speech</h2>
          <p className="text-center text-gray-500 md:text-xl sm:text-lg mb-6">
            while staying in sync with the original video and keeping the original speaker’s voice, prosody and tempo
          </p>
        </motion.div>

        <AnimatePresence mode="wait">
          {error && (
            <motion.div
              key="error"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="mx-auto max-w-md mb-8"
            >
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md shadow-md flex items-start">
                <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0 mt-0.5" />
                <div className="flex-grow">{error}</div>
                <button onClick={clearError} className="ml-2 text-red-500 hover:text-red-700">
                  <X className="w-5 h-5" />
                </button>
              </div>
            </motion.div>
          )}

          <motion.div
            key={currentStep}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-lg shadow-xl overflow-hidden max-w-4xl mx-auto"
          >
            {currentStep === 'upload' && <UploadRecordInterface onFileUpload={handleFileUpload} />}
            {currentStep === 'processing' && <ProcessingScreen progress={fileStatus?.progress || 0} />}
            {currentStep === 'results' && fileInfo && (
              <ResultsPage
                originalFile={`${API_URL}/original/${fileInfo.id}/`}
                processedFile={`${API_URL}/processed/${fileInfo.id}/`}
                outputFileName={fileStatus?.fname || null}
                fileType={fileInfo.type}
                onNewFile={handleNewFile}
                text={fileStatus!.text || ""}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </main>

      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Higgins Labs. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}
