import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VoiceClarityApp from './components/VoiceClarityApp';
import Land1 from './components/Land1';
import Land2 from './components/Land2';
import Land3 from './components/Land3';
import Land4 from './components/Land4';
import MaintenancePage from './components/MaintenancePage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<VoiceClarityApp />} />
          <Route path="/x-landing1" element={<Land1 />} />
          <Route path="/x-landing2" element={<Land2 />} />
          <Route path="/x-landing3" element={<Land3 />} />
          <Route path="/x-landing4" element={<Land4 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
